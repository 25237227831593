import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PajakData from './PajakData';


const pajakRoutes = [
  { path: '/pajak/default', element: <PajakData />, auth: authRoles.admin },
];

export default pajakRoutes;
