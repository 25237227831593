import {
    Box,
    Grid,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    Modal,
    Divider,
    TextField,
    Autocomplete

  } from "@mui/material";
  import { Breadcrumb, SimpleCard } from "app/components";
import { useState } from "react";

    const style = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,

    };
  
//   const [open, setOpen] = useState(0);
  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
]
  const subscribarList = [
    { id: 1, col1: '1', col2: 'Ade Waluyo P', col3: '20-10-2023', col4: '20:00', col5:'06:00',},
    { id: 2,  col1: '2', col2: 'Dicki', col3: '20-10-2023', col4: '20:00', col5:'06:00',  },
 
  ];

    

  const TablesData = () => {
    const [openedit, setOpenEdit] = useState(false);
    const [openhapus, setOpenHapus] = useState(false);
    const handleOpenDelete = () => {
        setOpenHapus(true);
    }
    const handleCloseDelete = () => {
        setOpenHapus(false);
    }
    const handleOpen = () => {
        setOpenEdit(true);
    }
    const handleClose = () => {
        setOpenEdit(false);
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (_, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    return (
      <Box width="100%" overflow="auto">
        <Modal open={openhapus} onClose={handleCloseDelete} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 500}}>
                <SimpleCard>
                   
                    <h3 id="child-modal-title">Apakah anda yakin ingin menghapus data ?</h3>
                    <center spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} md={2}>
                                <Button color="error" variant="contained">Ya</Button>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Button color="success" variant="contained" onClick={handleCloseDelete}>Tidak</Button>
                            </Grid>
                        </Grid>
                    </center>
                    
                </SimpleCard>
            </Box>
        </Modal>
        <Modal open={openedit} onClose={handleClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 600}}>
                <SimpleCard>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={8}>
                            <h2>Edit Data</h2>
                        </Grid>
                        <Grid item xs={2} md={2}>
                           
                        </Grid>
                        <Divider color='warning'/>
                        <Grid item xs={2} md={8}>
                            <TextField  label="Nama Mesin" sx={{ width: '55ch' }}/>   
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <TextField  label="Serial Mesin" sx={{ width: '55ch' }}/>   
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                sx={{ width: '55ch' }}
                                renderInput={(params) => <TextField {...params} label="Unit" />}
                            /> 
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <Button color="success" variant="contained">Simpan Data</Button>
                        </Grid>

                        {/* <Grid item xs={2} md={4}>
                            <TextField  label="Serial Number" sx={{ width: '47ch' }}/>   
                        </Grid>
                        <Grid item xs={2} md={6}>
                            <TextField  label="Unit" sx={{ width: '47ch' }}/>   
                        </Grid>
                        <Grid item xs={2} md={6}>
                            <TextField  label="" sx={{ width: '55ch' }}/>   
                        </Grid> */}
                    </Grid>
                </SimpleCard>
            </Box>
        </Modal>
        <StyledTable>
          <TableHead>
            <TableRow>
                <TableCell align="left">Kode Karyawan</TableCell>
                <TableCell align="left">Nama Karyawan</TableCell>
                <TableCell  align="center">Tanggal</TableCell>
                <TableCell  align="center">Jam Masuk</TableCell>
                <TableCell  align="center">Jam Keluar</TableCell>
                <TableCell align="center">Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribarList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((subscriber, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{subscriber.col1}</TableCell>
                  <TableCell align="left">{subscriber.col2}</TableCell>
                  <TableCell align="center"><Button color="primary">{subscriber.col3}</Button></TableCell>
                  <TableCell align="center"><Button color='success' variant="contained">{subscriber.col4}</Button></TableCell>
                  <TableCell align="center"><Button color='warning'  variant="contained">{subscriber.col5}</Button></TableCell>
                  <TableCell align="center">
                   
                    <IconButton onClick={handleOpenDelete}>
                      <Icon color="error">close</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
  
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={subscribarList.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
        />
      </Box>
    );
  };
  
  export default TablesData;
  