import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import karyawanRoutes from 'app/views/karyawan/KaryawanRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import invoiceRoutes from './views/invoice/InvoiceRoutes';
import absensiRoutes from './views/absensi/absensiRoutes';
import hrdRoutes from './views/hrd/hrdRoutes';
import perusahaanRoutes from './views/perusahaan/perusahaanRoutes';
import pajakRoutes from './views/pajak/pajakRoutes';
import staffrouter from './views/staff/StaffRouter';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes, 
      ...chartsRoute, 
      ...materialRoutes, 
      ...karyawanRoutes, 
      ...invoiceRoutes,
      ...absensiRoutes,
      ...hrdRoutes,
      ...perusahaanRoutes,
      ...pajakRoutes,
      ...staffrouter
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
