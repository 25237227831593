import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
// import ListData from './ListData';
// import BpjsData from './bpjs/BpjsData';

const ListData = Loadable(lazy(() =>
    import ('./ListData')));



const staffrouter = [
    { path: '/staff/default', element: < ListData /> , auth: authRoles.admin },
];

export default staffrouter;