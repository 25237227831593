import React from 'react'
import {useState, useEffect} from 'react'
import { 
    Box, 
    Button,
    Modal, 
    styled,
    Grid,
    TextField,
    Autocomplete, 
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Divider,
} from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import useAuth from 'app/hooks/useAuth';
import ViewsData from './components/ViewData';
// import { valHooks } from 'jquery';
import dayjs from 'dayjs';
const URL = process.env.REACT_APP_API_URL
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,

};
const status_p = [
    { label: 'SEND', id: 1 },
    { label: 'PROCES', id: 2 },
    { label: 'PAID', id: 3 },
]

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));
const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

const InvoiceData = () => {
    const {user} = useAuth();
    const [openfilter, setOpenFilter] = useState(false);
    const [openadd, setOpenAdd] = useState(false);
    const [repo, setRepo] = useState([]);
    const [selectunit, setSelectUnit] = useState([])
    const [openedit, setOpenEdit] = useState(false);
    const [openhapus, setOpenHapus] = useState(false);
    const [delid, setDelId] = useState();
    const [noinv, setNoinv] = useState();
    const [nofak, setNoFak] = useState();
    const [unitid, setUnitid] = useState();
    const [tgl, setTgl] = useState();
    const [totalupah, setTotalUpah] = useState(0);
    const [fee, setFee] = useState(0);
    const [ppn, setPpn] = useState(0);
    const [pph, setPph] = useState(0);
    const [total, setTotal] = useState(0);
    const [st, setSt] = useState();
    const [openview, setOpenView] = useState(false);
    const [idinv, setIdInv] = useState();
    const [datasend, setDataSend] = useState([]);
    const [datastatus, setDataStatus] = useState([]);
    

    const fetcData = async () => {
        if(user.role == 'SA') {
            await axios.get(URL+'/api/invoice').then((respon) => {
              const myRepo = respon.data.data;
              setRepo(myRepo);
            //   console.log(myRepo)
          })
        }else{
            await axios.get(URL+'/api/invoice/unit/'+user.unit).then((respon) => {
              const myRepo = respon.data.data;
              setRepo(myRepo);
            //   console.log(myRepo)
          })
        }
    }
    async function view(id, datal) {
        setOpenView(true)
        setIdInv(id)
        setDataSend(datal)
        await axios.get(URL+'/api/invoice/status/'+id).then((respon) => {
            const myRepo = respon.data.data;
            setDataStatus(myRepo);
            // console.log(myRepo)
        })
    }
    const handleCloseView = () => {
        setOpenView(false)
    }
    const fetchUnit = async () => {
        if(user.role == 'SA'){
            const fet = await axios.get(URL+'/api/unit').then((respon) => {
                return respon.data.data
            })
           const update = (fet) => fet.map(({unit, id}) => {
              return {label : unit, id:id}
           });
  
          setSelectUnit(update(fet));
        }else{
            const fet = await axios.get(URL+'/api/unit/'+user.unit).then((respon) => {
                return respon.data.data
            })
            const update = (fet) => fet.map(({unit, id}) => {
                return {label : unit, id:id}
             });
    
            setSelectUnit(update(fet));
        }

    }
    const handleSubmit = async () => {
        const total_tagihan = parseInt(totalupah)+parseInt(fee)+parseInt(ppn)+parseInt(pph)
        const datal = {
                no_invc : noinv,
                no_fak : nofak,
                unit_id:unitid.id,
                tanggal:dayjs(tgl).format('YYYY-MM-DD'),
                total_upah:totalupah,
                fee:fee,
                ppn:ppn,
                pph:pph,
                total:total_tagihan,
                st:0,

        }
        // console.log(datal)
        await axios.post(URL+'/api/invoice',datal).then((respon) => {
            if (respon.status === 200) {
                const datals = {
                    invoice_id: parseInt(respon.data.data.id),
                    tanggal:dayjs(tgl).format('YYYY-MM-DD'),
                    st:0,
                }
                axios.post(URL+'/api/invoice/status',datals).then((respone) => {
                    if (respone.status === 200) {
                        fetcData();
                        alert('Data sudah berhasil di tambah')
                    }
                })
            }else{
                alert('Data tidak berhasil di tambah');
            }
        })
    }
    const handleDelete = async () => {
        await axios.delete(URL+'/api/invoice/'+delid).then((respon) => {
           if (respon.status === 200) {
            fetcData();
            handleCloseDelete();
            alert('Data sudah di hapus');
           }else{
            handleCloseDelete();
            alert('Data tidak dapat di hapus');
           }
         })
    }
    useEffect(() => {
        fetcData()
        fetchUnit()
        // fetchBagian()
        // fetchJabatan()
    }, [])
    const rupiah = (number)=>{
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR"
        }).format(number);
      }
    function handleOpenDelete(data) {
        setDelId(data)
        setOpenHapus(true);
    }
    const handleCloseDelete = () => {
        setOpenHapus(false);
    }
    const handleOpen = () => {
        setOpenEdit(true);
    }
    const handleClose = () => {
        setOpenEdit(false);
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (_, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }
    const handleOpenAdd = () => {
        setOpenAdd(true);
    }
    const handleCloseAdd = () => {
        setOpenAdd(false);
    }
  return (
    <Container>
      <Box className="breadcrumb">
            <Breadcrumb routeSegments={[{ name: "Inovoice", path: "invoice/default" }, { name: "Invoice" }]} /> 
        </Box>
        <Modal open={openfilter} onClose={handleCloseFilter} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 1000}}>
                <SimpleCard>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={4}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={selectunit}
                                // sx={{ width: 300 }}
                                fullWidth
                                size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Unit:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="Start Date" fullWidth size='small'/> 
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="End Date" fullWidth size='small'/> 
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Button color='success' variant='contained'>Filter</Button>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Box>
        </Modal>
        <Modal open={openadd} onClose={handleCloseAdd} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 1000}}>
                <SimpleCard>
                    <h3 id="child-modal-title">Tambah Data</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={5}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={selectunit}
                                // sx={{ width: 300 }}
                                onChange={(e, value) => setUnitid(value)}
                                fullWidth
                                // size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Unit:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={5}>
                            <TextField label="No Invoice" onChange={(e) => setNoinv(e.target.value)} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <TextField label="No Faktur" onChange={(e) => setNoFak(e.target.value)} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                    'DatePicker',
                                    'TimePicker',
                                    'DateTimePicker',
                                    'DateRangePicker',
                                    ]}
                                >
                                    <DemoItem label='Tanggal'>
                                        <DatePicker onChange={(e) => setTgl(e)}/>
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} md={4}>
                            <TextField label="Total Gaji Karyawan" onChange={(e) => setTotalUpah(parseInt(e.target.value))} type='number' fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="Fee %" type='number' onChange={(e)=> setFee(parseInt(e.target.value))} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={4}>
                            <TextField label="Total" type='text' value={rupiah(parseInt(totalupah)+parseInt(fee))} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={4}>
                            <TextField label="PPN 11%" type='number' onChange={(e)=> setPpn(parseInt(e.target.value))} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="PPH23" type='number' onChange={(e) => setPph(parseInt(e.target.value))} fullWidth /> 
                        </Grid>
                        <Grid item xs={2} md={4}>
                            <TextField label="Total Tagihan " type='text' value={rupiah(parseInt(totalupah)+parseInt(fee)+parseInt(ppn)+parseInt(pph))} fullWidth /> 
                        </Grid>
                        {/* <Grid item xs={2} md={8}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={status_p}
                                // sx={{ width: 300 }}
                                onChange={(e, value) => setSt(value)}
                                fullWidth
                                // size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Status:" />}
                            />
                        </Grid> */}
                        <Grid item xs={2} md={8}>
                            <Button color='success' onClick={handleSubmit} variant='contained'>Simpan Data</Button>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Box>
        </Modal>
        <ViewsData data={openview} onClick={handleCloseView} id={idinv} datalist={datasend} datastatus={datastatus}/>
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                    <h2>Data Invoice</h2>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button color='warning' variant='contained' onClick={handleOpenAdd} startIcon= {<Icon>add</Icon>}>Tambah</Button>
                </Grid>
                <Grid item xs={2} md={2}>
                    <TextField label="Cari data" fullWidth size='small'/> 
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button color='success' variant='contained' onClick={handleOpenFilter} startIcon= {<Icon>menu</Icon>}>Filter</Button>
                </Grid>
                <Grid item xs={3} md={8}>
                    <Button>
                        <Icon fontSize="large" color="warning">print</Icon>
                    </Button> 
                    <Button>
                        <Icon fontSize="large" color="warning">import_export</Icon>
                    </Button>   
                </Grid>
                <Grid item xs={3} md={4}>  
                </Grid>
            </Grid>
            <Box width="100%" overflow="auto">
                <Modal open={openhapus} onClose={handleCloseDelete} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
                    <Box sx={{ ...style, width: 500}}>
                        <SimpleCard>
                        
                            <h3 id="child-modal-title">Apakah anda yakin ingin menghapus data Id:{delid}?</h3>
                            <center spacing={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} md={2}>
                                        <Button color="error" onClick={handleDelete} variant="contained">Ya</Button>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <Button color="success" variant="contained">Tidak</Button>
                                    </Grid>
                                </Grid>
                            </center>
                            
                        </SimpleCard>
                    </Box>
                </Modal>
                <Modal open={openedit} onClose={handleClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
                    <Box sx={{ ...style, width: 600}}>
                        <SimpleCard>
                            <Grid container spacing={2}>
                                <Grid item xs={2} md={8}>
                                    <h2>Edit Data</h2>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                
                                </Grid>
                                <Divider color='warning'/>
                                <Grid item xs={2} md={8}>
                                    <TextField  label="Nama Mesin" sx={{ width: '55ch' }}/>   
                                </Grid>
                                <Grid item xs={2} md={8}>
                                    <TextField  label="Serial Mesin" sx={{ width: '55ch' }}/>   
                                </Grid>
                                <Grid item xs={2} md={8}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={status_p}
                                        sx={{ width: '55ch' }}
                                        renderInput={(params) => <TextField {...params} label="Unit" />}
                                    /> 
                                </Grid>
                                <Grid item xs={2} md={8}>
                                    <Button color="success" variant="contained">Simpan Data</Button>
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Box>
                </Modal>
                <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Kode Invoice</TableCell>
                        <TableCell align="left">Unit</TableCell>
                        <TableCell  align="center">Tanggal</TableCell>
                        <TableCell  align="center">Total Upah</TableCell>
                        <TableCell  align="center">Fee 15%</TableCell>
                        <TableCell  align="center">Total</TableCell>
                        <TableCell  align="center">PPN 11%</TableCell>
                        <TableCell  align="center">PPH23</TableCell>
                        <TableCell  align="center">Total Invoice</TableCell>
                        <TableCell  align="center">Status</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {repo
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">{data.no_invc}</TableCell>
                            <TableCell align="left">{data.unit.unit}</TableCell>
                            <TableCell align="center"><Button color="primary">{dayjs(data.tanggal).format("YYYY-MM-DD")}</Button></TableCell>
                            <TableCell align="center"><Button color='primary'>{rupiah(data.total_upah)}</Button></TableCell>
                            <TableCell align="center"><Button color='primary'>{rupiah(data.fee)}</Button></TableCell>
                            <TableCell align="center">{rupiah(data.total_upah+data.fee)}</TableCell>
                            <TableCell align="center">{rupiah(data.ppn)}</TableCell>
                            <TableCell align="center">{rupiah(data.pph)}</TableCell>
                            <TableCell align="center">{rupiah(data.total)}</TableCell>
                            <TableCell align="center">{data.st === 3 ? <Button color='success' variant="contained">PAID</Button> : <Button color='warning' variant="contained">{data.st === 0 ? 'CREATED' : data.st === 1 ? "SEND" : "PROSES"}</Button>}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => view(data.id, data)}>
                                    <Icon color="success">visibility</Icon>
                                </IconButton>
                                <IconButton onClick={() => handleOpenDelete(data.id)}>
                                    <Icon color="error">close</Icon>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </StyledTable>
        
                <TablePagination
                sx={{ px: 2 }}
                page={page}
                component="div"
                rowsPerPage={rowsPerPage}
                count={repo.length}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </Box>
        </SimpleCard>
    </Container>
  )
}

export default InvoiceData
