import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ListData = Loadable(lazy(() => import ('./ListData')));
const BpjsData = Loadable(lazy(() => import ('./bpjs/BpjsData')));
const LihatData = Loadable(lazy(() => import ('./Karyawan/LihatData')));


const karyawanRoutes = [
    { path: '/karyawan/default', element: < ListData /> , auth: authRoles.admin },
    { path: '/karyawan/bpjs', element: < BpjsData /> , auth: authRoles.admin },
    { path: '/karyawan/lihat/:id', element: < LihatData /> , auth: authRoles.admin },
];

export default karyawanRoutes;