import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import LemburData from './lembur/LemburData';


const PerhariData = Loadable(lazy(() => import('./absensi_pehari/PerhariData')));
const PerbulanData = Loadable(lazy(() => import('./absensi_perbulan/PerbulanData')));
const MesinData = Loadable(lazy(() => import('./mesin_absen/MesinData')));
const Jadwal = Loadable(lazy(() => import('./jadwal/Jadwal')));
const Kehadiran = Loadable(lazy(() => import('./kehadiran/Kehadiran')));
const AbsensPeriod = Loadable(lazy(() => import('./absensi_period/AbsensiPeriod')));
const absensiRoutes = [
  { path: '/absensi/perhari', element: <PerhariData />, auth: authRoles.admin },
  { path: '/absensi/perbulan', element: <PerbulanData />, auth: authRoles.admin },
  { path: '/absensi/lembur', element: <LemburData />, auth: authRoles.admin },
  { path: '/absensi/mesin', element: <MesinData />, auth: authRoles.admin },
  { path: '/absensi/jadwal', element: <Jadwal />, auth: authRoles.admin},
  { path: '/absensi/kehadiran', element: <Kehadiran />, auth: authRoles.admin},
  { path: '/absensi/period', element: <AbsensPeriod />, auth: authRoles.admin}
];

export default absensiRoutes;
