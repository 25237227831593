import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';




const SuratPeringatan = Loadable(lazy(() => import('./surat_peringatan/SuratPeringatan')));
const PerpanjangKontrak = Loadable(lazy(() => import('./perpanjang_kontrak/PerpanjangKontrak')));
const KaryawanKeluar = Loadable(lazy(() => import('./karyawan_keluar/KaryawanKeluar')));
const KaryawanTrans = Loadable(lazy(() => import('./karyawan_transfer/karyawan_transfer')));
const Kecelakaan = Loadable(lazy(() => import('./kecelakaan/Kecelakaan')));
const Memo = Loadable(lazy(() => import('./memo/Memo')));

const hrdRoutes = [
  { path: '/hrd/surat-peringatan', element: <SuratPeringatan />, auth: authRoles.admin },
  { path: '/hrd/perpanjang-kontrak', element: <PerpanjangKontrak />, auth: authRoles.admin },
  { path: '/hrd/karyawan-keluar', element: <KaryawanKeluar />, auth: authRoles.admin },
  { path: '/hrd/transfer', element: <KaryawanTrans />,  auth: authRoles.admin},
  { path: '/hrd/kecelakaan', element: <Kecelakaan />,  auth: authRoles.admin},
  { path: '/hrd/memo', element: <Memo />,  auth: authRoles.admin}
  

];

export default hrdRoutes;
