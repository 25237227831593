import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import InvoiceData from './InvoiceData';
// import ListData from './ListData';

// const Analytics = Loadable(lazy(() => import('./Analytics')));

const invoiceRoutes = [
  { path: '/invoice/default', element: <InvoiceData />, auth: authRoles.admin },
];

export default invoiceRoutes;
