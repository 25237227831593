import {useState} from 'react'
import { Box, Button,Modal, styled,Grid,TextField, ToggleButtonGroup,Autocomplete, ToggleButton, Icon, Accordion, AccordionSummary, ExpandMoreIcon, Typography, AccordionDetails} from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo"
// import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Label } from '@mui/icons-material';

// import {DemoItem}

import TablesData from './components/TableData';
// import FormData from './components/FormData';
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,

};
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
]


const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));
const LemburData = () => {
    const [openfilter, setOpenFilter] = useState(false);
    const [openadd, setOpenAdd] = useState(false);
    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }
    const handleOpenAdd = () => {
        setOpenAdd(true);
    }
    const handleCloseAdd = () => {
        setOpenAdd(false);
    }
  return (
    <Container>
        <Box className="breadcrumb">
            <Breadcrumb routeSegments={[{ name: "Absensi", path: "absensi/lembur" }, { name: "Lembur" }]} /> 
        </Box>
        <Modal open={openfilter} onClose={handleCloseFilter} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 1000}}>
                <SimpleCard>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={4}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                // sx={{ width: 300 }}
                                fullWidth
                                size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Unit:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="Start Date" fullWidth size='small'/> 
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <TextField label="End Date" fullWidth size='small'/> 
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Button color='success' variant='contained'>Filter</Button>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Box>
        </Modal>
        <Modal open={openadd} onClose={handleCloseAdd} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style, width: 600}}>
                <SimpleCard>
                    <h3 id="child-modal-title">Tambah Data</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={2} md={8}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                // sx={{ width: 300 }}
                                fullWidth
                                // size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Unit:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                // sx={{ width: 300 }}
                                fullWidth
                                // size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Karyawan:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                    'DatePicker',
                                    'TimePicker',
                                    'DateTimePicker',
                                    'DateRangePicker',
                                    ]}
                                >
                                    <DemoItem label='Tanggal'>
                                        <DatePicker/>
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                    'DatePicker',
                                    'TimePicker',
                                    'DateTimePicker',
                                    'DateRangePicker',
                                    ]}
                                >
                                    <DemoItem label='Masuk'>
                                        <TimePicker />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                // sx={{ width: 300 }}
                                fullWidth
                                // size='small'
                                renderInput={(params) => <TextField {...params} label="Pilih Status:" />}
                            />
                        </Grid>
                        <Grid item xs={2} md={8}>
                            <Button color='success' variant='contained'>Simpan Data</Button>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </Box>
        </Modal>
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                    <h2>Data Lembur</h2>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button color='warning' variant='contained' onClick={handleOpenAdd} startIcon= {<Icon>add</Icon>}>Tambah</Button>
                </Grid>
                <Grid item xs={2} md={2}>
                    <TextField label="Cari data" fullWidth size='small'/> 
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button color='success' variant='contained' onClick={handleOpenFilter} startIcon= {<Icon>menu</Icon>}>Filter</Button>
                </Grid>
                <Grid item xs={3} md={8}>
                    <Button>
                        <Icon fontSize="large" color="warning">print</Icon>
                    </Button> 
                    <Button>
                        <Icon fontSize="large" color="warning">import_export</Icon>
                    </Button>   
                </Grid>
                <Grid item xs={3} md={4}>  
                </Grid>
            </Grid>
            <TablesData />
        </SimpleCard>
    </Container>
  )
}

export default LemburData
