import React, { useState, useEffect } from 'react';
import { Box, Button, styled, Grid,TextField, ToggleButtonGroup,Autocomplete, ToggleButton, Icon, Accordion, AccordionSummary, ExpandMoreIcon, Typography, AccordionDetails} from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";

// import TablesData from './Karyawan/TablesData';
// import FormData from './Karyawan/FormData';



const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

const PajakData = () => {
  return (
    <Container>
        <Box className="breadcrumb">
            <Breadcrumb routeSegments={[{ name: "Pajak PPH21", path: "pajak/default" }, { name: "Data" }]} /> 
        </Box>
    </Container>
  )
}

export default PajakData
