
const MatxLogo = ({ className }) => {

  return (
      <div>
        <img src="/assets/images/logo2.png" alt="" width={40}/>
      </div>
  );
};

export default MatxLogo;
