import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
// import UnitData from './Unit/UnitData';
// import BagianData from './Bagian/BagianData';
// import JabatanData from './Jabatan/JabatanData';



const UnitData = Loadable(lazy(() => import('./Unit/UnitData')));
const BagianData = Loadable(lazy(() => import('./Bagian/BagianData')));
const JabatanData = Loadable(lazy(() => import('./Jabatan/JabatanData')));
const MouData = Loadable(lazy(() => import('./Mou/MouData')));
const LaporanData = Loadable(lazy(() => import('./Unit/Laporan/LaporanData')));

const perusahaanRoutes = [
  { path: '/perusahaan/unit', element: <UnitData />, auth: authRoles.admin },
  { path: '/perusahaan/unit/laporan', element: <LaporanData />, auth: authRoles.admin },
  { path: '/perusahaan/divisi', element: <BagianData />, auth: authRoles.admin },
  { path: '/perusahaan/bagian', element: <JabatanData />, auth: authRoles.admin },
  { path: '/perusahaan/unit/mou/:id', element: <MouData />, auth: authRoles.admin },


];

export default perusahaanRoutes;
