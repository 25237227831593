export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'Staff', path: '/staff/default', icon: 'person' },
  // { name: 'Data Karyawan', path: '/karyawan/default', icon: 'person' },
  { label: 'UTAMA', type: 'label' },
  {
    name: 'Data Perusahaan',
    icon: 'list',
    // badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Unit', iconText: 'SI', path: '/perusahaan/unit' },
      { name: 'Devisi', iconText: 'SU', path: '/perusahaan/divisi' },
      { name: 'Bagian', iconText: 'FP', path: '/perusahaan/bagian' }
    ],
  },
  {
    name: 'Data Karyawan',
    icon: 'person',
    // badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Karyawan List', iconText: 'SI', path: '/karyawan/default' },
      { name: 'BPJS', iconText: 'SU', path: '/karyawan/bpjs' },
      // { name: 'KONTRAK', iconText: 'FP', path: '/session/forgot-password' },
    ],
  },

  {
    name: 'Data Hrd',
    icon: 'assignment_ind',
    // badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Surat Peringatan', iconText: 'SI', path: '/hrd/surat-peringatan' },
      { name: 'Kontrak Karyawan', iconText: 'SU', path: '/hrd/perpanjang-kontrak' },
      { name: 'Karyawan Keluar', iconText: 'FP', path: '/hrd/karyawan-keluar' },
      { name: 'Kecelakaan Kerja', iconText: 'FP', path: '/hrd/kecelakaan' },
      // { name: 'Transfer Karyawan', iconText: 'FP', path: '/hrd/transfer'}
      // { name: '', iconText: 'FP', path: '/session/forgot-password' },
    ],
  },
  { label: 'DATA', type: 'label' },
  {
    name: 'Data Absensi',
    icon: 'access_time',
    // badge: { value: '30+', color: 'secondary' },
    children: [
      { name: 'Mesin Absensi', iconText: 'SI', path: '/absensi/mesin' },
      { name: 'Absensi Perhari', iconText: 'SI', path: '/absensi/perhari' },
      { name: 'Absensi Perbulan', iconText: 'SU', path: '/absensi/perbulan' },
      { name: 'Absensi Period', iconText: 'SU', path: '/absensi/period' },
      { name: 'Kehadiran', iconText: 'SU', path: '/absensi/kehadiran' },
      { name: 'Jadwal', iconText: 'SU', path: '/absensi/jadwal' },
      // { name: 'Lembur', iconText: 'FP', path: '/absensi/lembur' },
      // { name: '', iconText: 'FP', path: '/session/forgot-password' },
    ],
  },
  { name: 'Lacak Invoice', path: '/invoice/default', icon: 'account_balance' },
  // { name: 'PPH 21', path: '/pajak/default', icon: 'account_balance_wallet' },
 
  // { label: 'LAPORAN', type: 'label' },
  // {
  //   name: 'Laporan Data',
  //   icon: 'assessment',
  //   children: [
  //     { name: 'Cetak Karyawan', iconText: 'SI', path: '/session/signin' },
  //     { name: 'Cetak Absensi', iconText: 'SU', path: '/session/signup' },
  //     { name: 'Cetak Surat Peringatan', iconText: 'FP', path: '/session/forgot-password' },
  //     { name: 'Cetak Kontrak Karyawan', iconText: '404', path: '/session/404' },
  //     { name: 'Cetak Karyawan Keluar', iconText: '404', path: '/session/404' },
  //     { name: 'Cetak Invoice', iconText: '404', path: '/session/404' },
  //     { name: 'Cetak PPH21', iconText: '404', path: '/session/404' },
  //   ],
  // },
];
