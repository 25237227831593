
import { useState, useEffect } from "react";
import { 
    Box, 
    Button,
    Modal, 
    styled,
    Grid,
    TextField,
    Autocomplete, 
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Divider,
} from "@mui/material";
import { Small,H3 } from 'app/components/Typography';
import { Breadcrumb, SimpleCard } from "app/components";
// import axios from "axios";
import dayjs from "dayjs";
const URL = process.env.REACT_APP_API_URL
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,

};
const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));

const ViewsData = (data) => {
    const [repo, setRepo] = useState([]);
    console.log(data.datastatus);
    const rupiah = (number)=>{
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR"
        }).format(number);
      }
    // const [open, setOpen] = useState(false)
    return(
        <Modal open={data.data} onClose={data.onClick} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
            <Box sx={{ ...style}}>
                <Grid container spacing={2}>
                    <Grid item xs md={6}>
                        <SimpleCard>
                            <Grid container spacing={2}>
                                <Grid item xs md={12}>
                                    <center>
                                        <H3>Data Invoice</H3>
                                    </center>
                                </Grid>
                                <Grid item xs md={12}>
                                    <StyledTable>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><Small>No Invoice :</Small></TableCell>
                                                <TableCell><Small>{data.datalist.no_invc}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>No Faktur :</Small></TableCell>
                                                <TableCell><Small>{data.datalist.no_fak}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>Tanggal Pembuatan :</Small></TableCell>
                                                <TableCell><Small>{dayjs(data.datalist.tanggal).format("DD-MM-YYYY")}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>Total Upah :</Small></TableCell>
                                                <TableCell><Small>{rupiah(data.datalist.total_upah)}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>Fee :</Small></TableCell>
                                                <TableCell><Small>{rupiah(data.datalist.fee)}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>PPN 11% :</Small></TableCell>
                                                <TableCell><Small>{rupiah(data.datalist.ppn)}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><Small>PPH 23 :</Small></TableCell>
                                                <TableCell><Small>{rupiah(data.datalist.pph)}</Small></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><H3>TOTAL TAGIHAN :</H3></TableCell>
                                                <TableCell><H3>{rupiah(data.datalist.total)}</H3></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </StyledTable>
                                </Grid>
                                <Grid item xs md={6}>
                                
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs md={6}>
                        <SimpleCard>
                            <Grid container spacing={2}>
                                <Grid item xs md={12}>
                                    <center>
                                        <H3>Data Tracking</H3>
                                    </center>
                                </Grid>
                                <Grid item xs md={12}>
                                    <StyledTable>
                                        <TableBody>
                                            {
                                                data.datastatus.map((datal, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell width="100"><Button variant='outlined' size='small' color='warning'>{datal.st === 3 ? 'PAID' : datal.st === 2 ? 'PROCES' : datal.st === 0 ? "CREATED" : 'SEND'}</Button></TableCell>
                                                        <TableCell>
                                                            {
                                                                datal.st === 1 ? <Small>Invoice dengan Nomor : {datal.invoice.no_invc} sedang proses pengiriman pada tanggal : {dayjs(datal.tanggal).format("DD-MM-YYYY")}</Small> : datal.st === 2 ? <Small>{data.memo}</Small> : datal.st === 0 ? <Small>Invoice dengan Nomor : {datal.invoice.no_invc} di buat pada tanggal : {dayjs(datal.tanggal).format("DD-MM-YYYY")}</Small> : <Small>{data.memo}</Small>
                                                            }
                                                            
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </StyledTable>
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
export default ViewsData